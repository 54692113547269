import Slider from './moduls/Slider';

document.addEventListener("DOMContentLoaded", function(){

    /** @private {NodeListOf<Element>} */
    let _nodeListSliders = document.querySelectorAll('[data-component=slider]');
    /** @private {number} */
    let _positionChangeWidth = 425;

    _nodeListSliders.forEach(function( _node ){

        const _Slider_ = new Slider( _node, {'class_prefix':'slider', 'position': ( window.innerWidth > _positionChangeWidth ? 'right' : 'center' ) } );

        _Slider_.addEventListener('resize',function(){

            _Slider_.setSettings( 'position', ( window.innerWidth > _positionChangeWidth ? 'right' : 'center' ) );
            _Slider_.refresh();
        },false );
    });
});