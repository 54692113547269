/**
 *
 * @param {Function} func
 */
function execute( func ){

    if( typeof func === 'function' ){

        arguments.length === 1
            ? func.call( null )
            : func.apply( null, ( [].slice.call( arguments ) ).slice( 1 ) );
    }
}

export { execute }