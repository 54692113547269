import * as __object__ from "../object/object";

/**
 *
 * @param any
 * @return {string}
 */
function get ( any ) {

    let class2type = {},
        class2typeArray = "Boolean Number String Function Array Date RegExp Object Error Symbol Arguments HTMLCollection CSSRuleList".split(" ");
    let toString = class2type.toString;

    if( !__object__.notEmpty( class2type ) ) {

        for ( let i = 0; i < class2typeArray.length; i++) {

            class2type["[object " + class2typeArray[i] + "]"] = class2typeArray[i].toLowerCase();
        }
    }

    if ( any === null ) { return any + ""; }

    return  ( typeof any === "object" || typeof any === "function" ) ?
        ( class2type[ toString.call( any ) ] || "object" ) :
        ( typeof any );
}

/**
 *
 * @param any
 * @return {boolean}
 */
function isUndefined( any ){

    return typeof any === undefined;
}

/**
 *
 * @param any
 * @return {boolean}
 */
function isFunction( any ){

    return typeof any === 'function';
}

function isNumber( any ){

    return ( typeof any === 'number' || ( typeof any === 'string' && parseInt( any,10  ) ) );
}

export { get, isUndefined, isFunction, isNumber };