import * as __object__ from "../object/object";
import * as __string__ from "../string/string";
import * as __array__ from "../array/array";
import * as __class__ from "../class/class";

/**
 *
 * @param {*} element
 * @return {boolean}
 */
function isNode( element ){

    return ( element && ( element instanceof HTMLElement || element instanceof Document || element instanceof Window ) );
}

/**
 *
 * @param element
 * @return {boolean}
 */
function isTextNode( element ){

    return ( typeof element === 'object' && element instanceof Text );
}

/**
 *
 * @param {HTMLElement} element
 * @param {HTMLElement} elementParent
 * @param {null|string} order
 * @return {*}
 */
function append( element, elementParent, order = null ){

    if( isNode( element ) && isNode( elementParent ) ) {
        switch ( order ){
            case 'first':
                elementParent.insertBefore( element, elementParent.firstChild );
                break;
            case 'before':
                elementParent.parentElement.insertBefore( element, elementParent );
                break;
            case 'after':
                elementParent.parentElement.insertBefore( element, elementParent.nextSibling );
                break;
            case 'last': default:
                elementParent.appendChild( element );
                break;
        }
    }
}

/**
 *
 * @param {null|string|Array} _class
 * @param {null|Array} _attr
 * @param {null|HTMLElement} parentElement
 * @param {string} _tagName
 * @param {null|string|[]|HTMLElement} _children
 * @return {HTMLElement}
 */
function create( _class, _attr= [], parentElement= null, _tagName = 'div', _children = null ){

    const _node = document.createElement( ( _tagName ) );

    if( __string__.notEmpty( _class ) || __array__.notEmpty( _class ) ) {

        __class__.add( _node, _class )
    }

    if( __object__.notEmpty( _attr ) ){

        for( let i in _attr ){

            if( _attr.hasOwnProperty(i) ){

                _node.setAttribute( i, _attr[i] );
            }
        }
    }

    if( _children ){

        if( isNode( _children ) || isTextNode( _children ) ){

            // _node.appendChild( _children );
            append( _children, _node, null );

        }else if( __array__.notEmpty( _children ) && _children.length > 0 ){

            _children.map(function( _n){

                if( isNode( _n ) || isTextNode( _n ) ) {
                    append( _n, _node, null );
                    // _node.appendChild( _n );
                }
            });
        }else if( __string__.notEmpty( _children ) ){

            _node.innerHTML = _children;
        }
    }

    if( isNode( parentElement ) ){

        append( _node, parentElement, null );
        // parentElement.appendChild( _node );
    }

    return _node;
}

/**
 *
 * @param {HTMLElement} element
 */
function remove( element ){

    if ( isNode( element ) ) {

        try{ element.parentNode.removeChild( element ); }catch(e){  }
    }
}

/**
 *
 * @param {HTMLElement} element
 */
function empty( element ){

    if ( isNode( element ) && element.childNodes.length > 0 ) {

        while( element.firstChild ) {

            element.removeChild( element.firstChild );
        }
    }
}

/**
*
* @param {HTMLElement} parentElement
* @param {string} _class
* @return {*[]}
*/
function getByClassNameAll( parentElement, _class ){


    if( isNode( parentElement ) && __string__.notEmpty( _class ) ){

        /** @private {HTMLCollectionOf<Element>} */
        let _nodeListResult = parentElement.getElementsByClassName( _class );

        return ( _nodeListResult && _nodeListResult.length > 0 )
            ? Array.prototype.slice.call( _nodeListResult )
            : [];
    }
    return [];
}

/**
*
* @param {HTMLElement} parentElement
* @param {string} _class
* @return {null|HTMLElement}
*/
function getByClassName( parentElement, _class ){

    if( isNode( parentElement ) && __string__.notEmpty( _class ) ){

        /** @private {HTMLCollectionOf<Element>} */
        let _nodeListResult = parentElement.getElementsByClassName( _class );

        if( _nodeListResult && _nodeListResult.length > 0 ){

            return _nodeListResult[0];
        }
    }
    return null;
}

/**
 *
 * @param {HTMLElement} element
 * @return {null|HTMLElement}
 */
function cloneHidden( element ){

    /** @private {null|HTMLElement} */
    let _nodeClone = null;

    if( isNode( element ) ) {

        _nodeClone = element.cloneNode(true);

        _nodeClone.style.position =     'absolute';
        _nodeClone.style.opacity =      '0';
        _nodeClone.style.visibility =   'hidden';
        _nodeClone.style.transition =   'none';

        append( _nodeClone, element.parentElement );
    }

    return _nodeClone;
}

/**
 *
 * @param {HTMLElement} element
 * @param {null|string} withClass
 * @return {null|{}}
 */
function getDimension( element, withClass = null ){

    let _dimension = null;

    function _get( _clone ){

        /** @private {DOMRect} */
        const _cloneBoundingClientRect = _clone.getBoundingClientRect();

        return {

            width: _cloneBoundingClientRect.width,
            height: _cloneBoundingClientRect.height
        }
    }

    if( isNode( element ) ) {

        let _clone = cloneHidden( element );

        _dimension = getClientRect( _clone );

        if( __string__.notEmpty( withClass ) ){

            __class__.add( _clone, withClass );

            _dimension[ withClass ] = _get( _clone );
        }

        remove( _clone );
    }

    return _dimension;
}

/**
 *
 * @param element
 * @return {null|{top: number, left: number, bottom: number, width: number, right: number, height: number}}
 */
function getClientRect( element ){

    if( isNode( element ) ) {

        /** @private {DOMRect} */
        const _cloneBoundingClientRect = element.getBoundingClientRect();

        return {

            width: _cloneBoundingClientRect.width,
            height: _cloneBoundingClientRect.height,
            left: _cloneBoundingClientRect.left,
            right: _cloneBoundingClientRect.right,
            top: _cloneBoundingClientRect.top,
            bottom: _cloneBoundingClientRect.bottom
        }
    }

    return null;
}

export { isNode, isTextNode, append, create, remove, empty, getByClassNameAll, getByClassName, cloneHidden, getDimension, getClientRect };