/**
 *
 * @param {*} obj
 * @return {boolean}
 */
function notEmpty( obj ){

    if( obj && typeof obj === 'object' ){

        for( let i in obj) {

            return true;
        }
    }

    return false;
}

/**
 *
 * @param {{}}obj
 * @param {string} key
 * @param {*} defaultValue
 * @return {*|null}
 */
function getByKey( obj, key, defaultValue = undefined ){

    return ( obj && key && typeof obj === 'object' && typeof obj[key] !== 'undefined' )
        ? obj[key]
        : defaultValue;
}

export { notEmpty, getByKey };