/**
 *
 * @param {*} string
 * @return {boolean}
 */
function notEmpty( string ){

    if( typeof string === 'undefined' || arguments.length > 0 ){

        for ( let i = 0; i < arguments.length; i++ ){

            arguments[i] = typeof arguments[i] === 'number' ? arguments[i].toString() : arguments[i];

            if( !( typeof arguments[i] === 'string' && arguments[i].length > 0 ) ){

                return false;
            }
        }

        return true;
    }else{

        return false;
    }
}


/**
 *
 * @param {string} string
 * @returns {string}
 */
function trim ( string ){

    /** @type {RegExp} */
    const rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;

    if( notEmpty( string ) ){

        if( typeof String().trim === 'function' ){

            return string.trim() || "";
        }else{
            return ( string + "" ).replace( rtrim, "" );
        }
    }else{

        return "";
    }
}

export { notEmpty, trim };