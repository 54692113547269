import * as __type__ from "../type/type";

/**
 *
 * @param arr
 * @return {boolean}
 */
function isArray( arr ){


    return ( __type__.get ( arr ) === 'array' );
}

/**
 *
 * @param {[]} arr
 * @return {boolean}
 */
function notEmpty( arr ){

    return ( isArray( arr ) && arr.length > 0 );
}

export { notEmpty, isArray };