import * as __array__ from "../array/array";
import * as __node__ from "../node/node";
import * as __string__ from "../string/string";

/** @private {RegExp} */
const __regClass__ = /[\t\r\n\f]/g;

/** @private {RegExp} */
const __regNotWhite__ = ( /\S+/g );

/**
 *
 * @param {HTMLElement} element
 * @param {string|[]} string
 */
function add( element, string ){

    if( string && __node__.isNode( element ) ){

        if( __array__.isArray( string ) ){

            string = string.join(' ');
        }

        string = __string__.trim( string );

        if( typeof element.classList === 'object' && typeof element.classList.add === 'function' ){

            if( string.indexOf(" ") !== -1 ){

                /** @private {string[]} */
                let _arrString = string.split(" ");

                if( _arrString ) {

                    for ( let i = 0; i < _arrString.length; i++ ) {

                        if( __string__.notEmpty( _arrString[i] ) ){

                            element.classList.add(_arrString[i]);
                        }
                    }
                }
            }else{

                element.classList.add( string );
            }
        }else {

            let classes, cur, clazz, j, finalValue,
                proceed = __string__.notEmpty( string );

            if (proceed) {

                classes = ( string || "").match(__regNotWhite__) || [];
                cur = element.className ? (" " + element.className + " ").replace(__regClass__, " ") : " ";

                if (cur) {
                    j = 0;
                    while ((clazz = classes[j++])) {
                        if (cur.indexOf(" " + clazz + " ") < 0) {
                            cur += clazz + " ";
                        }
                    }

                    finalValue = __string__.trim( cur );

                    if (element.className !== finalValue) {
                        element.className = finalValue;
                    }

                }
            }
        }
    }
}


/**
 *
 * @param {HTMLElement} element
 * @param {string|[]} string
 */
function remove( element, string ){

    if( string && __node__.isNode( element ) ){

        if( __array__.isArray( string ) ){

            string = string.join(' ');
        }

        if( typeof element.classList === 'object' && typeof element.classList.remove === 'function' ){

            if( string.indexOf(" ") !== -1 ){

                let _arrString = string.split(" ");

                if( _arrString ) {

                    for (let i = 0; i < _arrString.length; i++ ) {

                        if( __string__.notEmpty( _arrString[i] ) ){

                            element.classList.remove( _arrString[i] );
                        }
                    }
                }
            }else{

                element.classList.remove( string );
            }
        }else {

            let classes, cur, clazz, j, finalValue,
                proceed = arguments.length === 0 || __string__.notEmpty( string );

            if ( proceed ) {

                classes = (string || "").match(__regNotWhite__) || [];

                cur = element.className ? (" " + element.className + " ").replace(__regClass__, " ") : "";

                if (cur) {

                    j = 0;

                    while ((clazz = classes[j++])){
                        while (cur.indexOf(" " + clazz + " ") >= 0) {
                            cur = cur.replace(" " + clazz + " ", " ");
                        }
                    }

                    finalValue = string ? __string__.trim(cur) : "";

                    if (element.className !== finalValue) {
                        element.className = finalValue;
                    }
                }
            }
        }
    }
}


/**
 *
 * @param {HTMLElement} element
 * @param {string} string
 * @returns {boolean}
 */
function has( element, string ){

    if( string && __node__.isNode( element ) ){

        if( typeof element.classList === 'object' && typeof element.classList.contains === 'function' ){

            return element.classList.contains( string );
        }else {

            const className = " " + string + " ";
            return ( __node__.isNode( element ) && (" " + element.className + " ").replace( __regClass__, " " ).indexOf( className ) >= 0 );
        }
    }

    return false;
}


/**
 *
 * @param {HTMLElement} element
 * @param {string} string
 * @return {boolean}
 */
function toggle( element, string ){

    /** @private {boolean} */
    const _bHas = has( element, string );

    _bHas ? remove( element, string ) : add( element, string );

    return _bHas;
}

export { add, remove, has, toggle }