import * as __node__        from "../lib/node/node";
import * as __array__       from "../lib/array/array";
import * as __string__      from "../lib/string/string";
import * as __class__       from "../lib/class/class";
import * as __object__      from "../lib/object/object";
import * as __function__    from "../lib/function/function";
import * as __type__        from "../lib/type/type";
import {getClientRect} from "../lib/node/node";
import {isNumber} from "../lib/type/type";

// Нужно что-бы сработала анимация транзишн при добавлении класса
// @see https://www.charistheo.io/blog/2021/02/restart-a-css-animation-with-javascript/#restarting-a-css-animation
// _node.offsetHeight;

/**
 *
 * @param {HTMLElement} node
 * @param {{}} settings
 * @constructor
 */
function Slider( node , settings ){

    const __slider__ = this;

    /** @private {string} */
    const _STRING_TRUE_ =   'true';
    /** @private {string} */
    const _STRING_RIGHT_ =  'right';
    /** @private {string} */
    const _STRING_LEFT_ =   'left';
    /** @private {string} */
    const _STRING_CENTER_ = 'center';
    /** @private {string} */
    const _STRING_POSITION_ = 'position';
    /** @private {string} */
    const _STRING_LOOP_ = 'loop';
    /** @private {string} */
    const _STRING_DISABLED_ = 'disabled';
    /** @private {string} */
    const _STRING_ITEM_INDEX_ = '__index';
    /** @private {string} */
    const _STRING_RESIZE_ = 'resize';

    /** @private {string} */
    const _STRING_DATA__ =          'data-';
    /** @private {string} */
    const _STRING_DATA_POSITION_ =  _STRING_DATA__ + _STRING_POSITION_;
    /** @private {string} */
    const _STRING_DATA_LOOP_ =  _STRING_DATA__ + _STRING_LOOP_;

    /** @private {string} */
    let _CLASS_SLIDER_PREFIX = 'slider-';
    /** @private {string} */
    const _CLASS_SLIDER_ITEM_PREFIX = _CLASS_SLIDER_PREFIX + 'item-';
    /** @private {string} */
    const _CLASS_SLIDER_INFORMER_PREFIX = _CLASS_SLIDER_PREFIX + 'informer-';
    /** @private {string} */
    const _CLASS_SLIDER_PAGINATION_PREFIX = _CLASS_SLIDER_PREFIX + 'pagination-';

    const _CLASS_ = {

        list:                   _CLASS_SLIDER_PREFIX + 'list',
        list_item:              _CLASS_SLIDER_PREFIX + 'item',
        container:              _CLASS_SLIDER_PREFIX + 'container',
        informer:               _CLASS_SLIDER_PREFIX + 'informer',
        pagination:             _CLASS_SLIDER_PREFIX + 'pagination',

        item_informer:          _CLASS_SLIDER_ITEM_PREFIX + 'informer',
        item_head:              _CLASS_SLIDER_ITEM_PREFIX + 'head',
        item_title:             _CLASS_SLIDER_ITEM_PREFIX + 'title',
        item_description:       _CLASS_SLIDER_ITEM_PREFIX + 'description',

        informer_wrapper:       _CLASS_SLIDER_INFORMER_PREFIX + 'wrapper',
        informer_container:     _CLASS_SLIDER_INFORMER_PREFIX + 'container',
        informer_head:          _CLASS_SLIDER_INFORMER_PREFIX + 'head',
        informer_title:         _CLASS_SLIDER_INFORMER_PREFIX + 'title',
        informer_description:   _CLASS_SLIDER_INFORMER_PREFIX + 'description',

        pagination_forward:     _CLASS_SLIDER_PAGINATION_PREFIX + 'arrow forward',
        pagination_backward:    _CLASS_SLIDER_PAGINATION_PREFIX + 'arrow backward',
        pagination_label:       _CLASS_SLIDER_PAGINATION_PREFIX + 'label',
        pagination_label_current:   'current',
        pagination_label_total:     'total',
        pagination_breadcrumbs: _CLASS_SLIDER_PAGINATION_PREFIX + 'breadcrumbs',

        position_right:         _CLASS_SLIDER_PREFIX + _STRING_POSITION_ + '-right',
        position_center:        _CLASS_SLIDER_PREFIX + _STRING_POSITION_ + '-center',

        active:     'active',
        sliding:    'sliding',
        disabled:   'disabled',
        clone:      'clone'
    };


    /** @private {number} */
    const _POSITION_LEFT_ =     0;
    /** @private {number} */
    const _POSITION_CENTER_ =   1;
    /** @private {number} */
    const _POSITION_RIGHT_ =    2;

    const _POSITION = {
        'left':     _POSITION_LEFT_,
        'center':   _POSITION_CENTER_,
        'right':    _POSITION_RIGHT_,
    }

    const _NODE_ = {

        list:                   null,
        items:                  [],
        clones_left:            [],
        clones_right:           [],
        container:              null,

        pagination:             null,
        pagination_forward:     null,
        pagination_backward:    null,
        pagination_label:       null,
        pagination_label_current:null,
        pagination_label_total:  null,
        pagination_breadcrumbs: null,

        informer:               null,
        informer_wrapper:     null
    };

    const _INFORMER_ = [];

    const _SETTINGS_ = {

        position:                   _POSITION_LEFT_,    // Slider active element position:  0 - left, 1 - right, 3 - center

        pagination:                 true,
        pagination_label:           true,
        arrow:                      true,

        pagination_independently:   false,
        informer:                   false,

        loop:                       true,
        duration:                   500,
        animation:                  true
    };

    // ****************************************

    /** @private {boolean} */
    let _bSliding = false;
    /** @private {number} */
    let _indexActive = -1;
    /** @private {number} */
    let _indexMaximumPosition = -1;

    /** @private {number} */
    let _positionItemActive = 0;

    /** @private {boolean} */
    let _bCloneCreated = false;

    // ****************************************

    const _events = {};

    // ****************************************
    // Functions
    // ****************************************

    /**
     *
     * @return {HTMLElement}
     * @private
     */
    function _getContainer(){

        if( !_NODE_.container ){

            _NODE_.container = __node__.getByClassName( node, _CLASS_.container );

            if( !_NODE_.container ){

                _NODE_.container = __node__.create( _CLASS_.container, null, node, 'div' );
            }
        }

        return _NODE_.container;
    }

    function _getInformer(){

        if( !_NODE_.informer ){

            _NODE_.informer = __node__.getByClassName( node, _CLASS_.informer );

            if( !_NODE_.informer ){

                _NODE_.informer = __node__.create( _CLASS_.informer, null, node, 'div' );

            }else{

                _NODE_.informer_wrapper = __node__.getByClassName( node, _CLASS_.informer_wrapper );
            }

            if( !_NODE_.informer_wrapper ) {

                _NODE_.informer_wrapper = __node__.create(_CLASS_.informer_wrapper, null, _NODE_.informer, 'div');
            }
        }

        return _NODE_.informer;
    }

    function _getPagination(){

        if( !_NODE_.pagination ){

            _NODE_.pagination = __node__.getByClassName( node, _CLASS_.pagination );

            if( !_NODE_.pagination ){

                _NODE_.pagination = __node__.create( _CLASS_.pagination, null, null, 'div' );
            }

            if( _SETTINGS_.pagination_label ){

                _getPaginationLabel();
            }

            if( _SETTINGS_.arrow ){

                _NODE_.pagination_backward = __node__.getByClassName( node, _CLASS_.pagination_backward );
                _NODE_.pagination_forward = __node__.getByClassName( node, _CLASS_.pagination_forward );

                if( !_NODE_.pagination_forward ){

                    _NODE_.pagination_forward = __node__.create( _CLASS_.pagination_forward, null, null, 'div' );
                    __node__.append( _NODE_.pagination_forward, _NODE_.pagination, 'last' );
                }

                if( !_NODE_.pagination_backward ){

                    _NODE_.pagination_backward = __node__.create( _CLASS_.pagination_backward, null, null, 'div' );
                    __node__.append( _NODE_.pagination_backward, _NODE_.pagination, 'first' );
                }
            }
        }

        return _NODE_.pagination;
    }

    function _getPaginationLabel(){

        if( !_NODE_.pagination_label ) {

            _NODE_.pagination_label = __node__.getByClassName( ( _NODE_.pagination || node ), _CLASS_.pagination_label );

            if ( !_NODE_.pagination_label ){

                _NODE_.pagination_label = __node__.create( _CLASS_.pagination_label, null, _NODE_.pagination, 'div' );
            }

            _NODE_.pagination_label_current = __node__.getByClassName( _NODE_.pagination_label, _CLASS_.pagination_label_current );

            if( !_NODE_.pagination_label_current ){

                _NODE_.pagination_label_current = __node__.create( _CLASS_.pagination_label_current, null, _NODE_.pagination_label, 'div' );
            }

            _NODE_.pagination_label_total = __node__.getByClassName( _NODE_.pagination_label, _CLASS_.pagination_label_total );

            if( !_NODE_.pagination_label_total ){

                _NODE_.pagination_label_total = __node__.create( _CLASS_.pagination_label_total, null, _NODE_.pagination_label, 'div' );
            }
        }

        return _NODE_.pagination_label;
    }

    /**
     *
     * @return {HTMLElement|null}
     * @private
     */
    function _getList(){

        if( !_NODE_.list ){

            _NODE_.list = __node__.getByClassName( node, _CLASS_.list );
        }

        return _NODE_.list;
    }

    /**
     *
     * @return {[]}
     * @private
     */
    function _getListItems(){

        if( !__array__.notEmpty( _NODE_.items ) ){

            _NODE_.items = __node__.getByClassNameAll( _NODE_.list, _CLASS_.list_item );
        }

        return _NODE_.items;
    }

    /**
     *
     * @param {HTMLElement} _nodeItem
     * @param {number} _index
     * @private
     */
    function _initListItem( _nodeItem , _index ){

        _nodeItem.addEventListener('click', _listItemOnClick,false );
    }

    function _initSettings(){

        // ****************************************
        // From settings var
        // ****************************************

        if( __object__.notEmpty( settings ) ){

            // ****************************************
            // Class
            // ****************************************

            let _temp = __object__.getByKey( settings, 'class_prefix', null );
            _temp = __string__.notEmpty( _temp ) ? _temp + '-' : null;

            if( _CLASS_SLIDER_PREFIX !== _temp ){

                const _sRegPrefix = '^' + _CLASS_SLIDER_PREFIX;

                let _regPrefix = new RegExp( _sRegPrefix,"g" );

                for( let i in _CLASS_ ){

                    if( _CLASS_.hasOwnProperty( i ) ){

                        _CLASS_[i] = _CLASS_[i].replace(_regPrefix, _temp );
                    }
                }

                _CLASS_SLIDER_PREFIX = _temp;
            }
        }

        // ****************************************
        // Position
        // ****************************************

        _setSettingsPosition( node.getAttribute( _STRING_DATA_POSITION_ ) || __object__.getByKey( settings, _STRING_POSITION_, _SETTINGS_.position ) );
        node.removeAttribute( _STRING_DATA_POSITION_ );

        if( node.hasAttribute( _STRING_DATA_LOOP_ ) ){

            _SETTINGS_.loop = node.getAttribute( _STRING_DATA_LOOP_ ) === _STRING_TRUE_;
            node.removeAttribute( _STRING_DATA_LOOP_ );
        }
    }

    function _initDom(){

        if( _NODE_.list ) {

            if( __array__.notEmpty( _NODE_.items ) ) {

                for( let i = 0; i < _NODE_.items.length; i++ ){

                    /** @private {HTMLElement} */
                    let _nodeListItem = _NODE_.items[i];

                    _nodeListItem[_STRING_ITEM_INDEX_] = i;

                    if( __class__.has( _nodeListItem, _CLASS_.active ) ){

                        _indexActive = i;
                    }

                    // ****************************************
                    // Informer
                    // ****************************************

                    /** @private {HTMLElement|null} */
                    let _nodeItemInformer = __node__.getByClassName( _nodeListItem, _CLASS_.item_informer );

                    if( _nodeItemInformer ){

                        _INFORMER_[i] = {

                            node:           _nodeItemInformer,

                            head:           __object__.getByKey( __node__.getByClassName( _nodeItemInformer, _CLASS_.item_head ), 'innerText', null ),
                            title:          __object__.getByKey( __node__.getByClassName( _nodeItemInformer, _CLASS_.item_title ), 'innerText', null ),
                            description:    __object__.getByKey( __node__.getByClassName( _nodeItemInformer, _CLASS_.item_description ), 'innerText', null ),
                        };

                        _SETTINGS_.informer = true;

                        __node__.remove( _nodeItemInformer );
                    }
                }
            }
        }
    }

    /**
     *
     * @param {MouseEvent} _event
     * @private
     */
    function _arrowOnClick( _event ){

        /** @private {HTMLElement} */
        const _target = _event.target;

        if( ! ( __class__.has( _target, _CLASS_.disabled ) || _target.hasAttribute( _STRING_DISABLED_ ) ) ) {

            _setNavigationDirection( _target === _NODE_.pagination_forward ? 1 : -1 );
        }
    }

    function _listItemOnClick( _event ){

        /** @private {HTMLElement} */
        let _nodeListItem = _event.currentTarget;

        if( _nodeListItem[ _STRING_ITEM_INDEX_ ] >= 0 ){

            _goToIndex( _nodeListItem[ _STRING_ITEM_INDEX_ ], _SETTINGS_.animation );
        }
    }

    // ****************************************

    /**
     *
     * @param {number} _index
     * @param {boolean} _bAnimate
     * @private
     */
    function _goToIndex( _index, _bAnimate = false ){

        function _slidingEnded( _indexReturn ){

            _indexActive = _indexReturn;

            _NODE_.pagination_label_total.innerText = _NODE_.items.length;
            _NODE_.pagination_label_current.innerText = ( _indexActive + 1 );

            if( !_SETTINGS_.loop ) {

                ( _index === 0 )
                    ? __class__.add( _NODE_.pagination_backward, _STRING_DISABLED_ )
                    : __class__.remove( _NODE_.pagination_backward, _STRING_DISABLED_ );

                ( _index === ( _NODE_.items.length - 1 ) )
                    ? __class__.add( _NODE_.pagination_forward, _STRING_DISABLED_ )
                    : __class__.remove( _NODE_.pagination_forward, _STRING_DISABLED_ );
            }
        }

        if( !_bSliding ) {

            if( _bAnimate === false || _index !== _indexActive ){

                _slideToIndex( _index, _bAnimate, _slidingEnded );
                _renderInformer( _index, _bAnimate );
            }
        }
    }

    /**
     *
     * @param {number} _direction
     * @private
     */
    function _setNavigationDirection( _direction ){

        if( _indexMaximumPosition > 0 ) {

            /** @private {number} */
            let _index = _monitorIndex( _indexActive + _direction );

            if ( _index !== _indexActive ){

                _goToIndex( _index, _SETTINGS_.animation );
            }
        }
    }

    /**
     *
     * @param {number} _index
     * @return {number}
     * @private
     */
    function _monitorIndex( _index ){

        _index = ( _index < 0 )
            ? ( _SETTINGS_.loop ? _indexMaximumPosition : 0 )
            : _index;

        _index = ( _index > _indexMaximumPosition )
            ? ( _SETTINGS_.loop ? 0 : _indexMaximumPosition )
            : _index;

        return _index;
    }

    // ****************************************

    function _getPositionX( _index ){

        /** @private {number} */
        let _position = 0;
        /** @private {null|number} */
        let _positionTransit = null;

        /** @private {HTMLElement} */
        const _nodeItemNew = _NODE_.items[ _index ];
        /** @private {HTMLElement} */
        const _nodeItemActive = _NODE_.items[ _indexActive ];

        /** @private {null|{top: number, left: number, bottom: number, width: number, right: number, height: number}} */
        const _clientRectContainer = __node__.getClientRect( _NODE_.container );
        /** @private {null|{top: number, left: number, bottom: number, width: number, right: number, height: number}} */
        const _clientRectItemNew = __node__.getClientRect( _nodeItemNew );
        /** @private {null|{top: number, left: number, bottom: number, width: number, right: number, height: number}} */
        const _clientRectItemActive = __node__.getClientRect( _nodeItemActive );
        /** @private {CSSStyleDeclaration} */
        const _styleItemNew = getComputedStyle( _nodeItemNew );
        /** @private {number} */
        const _itemNewMarginRight = ( parseInt( _styleItemNew.marginRight, 10 ) || 0 );
        /** @private {number} */
        const _marginDelta = ( _clientRectItemActive.width + _itemNewMarginRight ) / 2;
        /** @private {number} */
        const _widthDelta = _clientRectContainer.width - _clientRectItemActive.width;

        /** @private {number} */
        let _directionDelta = 0;
        /** @private {number} */
        let _positionDelta = 0;

        // ****************************************
        // Direction
        // ****************************************

        if ( _index !== 0 && _index > _indexActive ) {

            _directionDelta = _marginDelta;
        }

        // ****************************************
        // POSITION
        // ****************************************

        if( _SETTINGS_.position === _POSITION.left ){

            _positionDelta = 0;
        }else if( _SETTINGS_.position === _POSITION.center ){

            _positionDelta = _widthDelta / 2;
        }else if( _SETTINGS_.position === _POSITION.right ){

            _positionDelta = _widthDelta;
        }

        // ****************************************
        // Extreme positions => Transit
        // ****************************************

        if( _SETTINGS_.loop && ( _indexActive === 0 && _index === _indexMaximumPosition || _indexActive === _indexMaximumPosition && _index === 0 ) ) {

            /** @private {boolean} */
            const _bUseCloneRightSide = _indexActive === 0 && _index === 4;
            /** @private {number} */
            let _directionCloneDelta = 0;

            /** @private {HTMLElement} */
            let _nodeItemClone = _bUseCloneRightSide ? _NODE_.clones_right[ _index ] : _NODE_.clones_left[ _index ];
            /** @private {null|{top: number, left: number, bottom: number, width: number, right: number, height: number}} */
            let _clientRectItemClone = __node__.getClientRect( _nodeItemClone );

            /** @private {CSSStyleDeclaration} */
            const _styleItemClone = getComputedStyle( _nodeItemClone );
            /** @private {number} */
            const _itemCloneMarginRight = ( parseInt( _styleItemClone.marginRight, 10 ) || 0 );
            /** @private {number} */
            const _marginCloneDelta = ( _clientRectItemActive.width + _itemCloneMarginRight ) / 2;

            if( _SETTINGS_.position === _POSITION.left ){

                _directionCloneDelta = _bUseCloneRightSide ? 0 : ( _clientRectItemActive.width - _clientRectItemClone.width );
            }else if( _SETTINGS_.position === _POSITION.center ){

                _directionCloneDelta = _bUseCloneRightSide ? 0 : _marginCloneDelta;
            }else if( _SETTINGS_.position === _POSITION.right ){

                _directionCloneDelta = _bUseCloneRightSide ? 0 : _marginCloneDelta;
            }

            _positionTransit = _clientRectContainer.left - _clientRectItemActive.left
                + _positionItemActive
                + _positionDelta
                + _directionDelta
                - _directionCloneDelta
            ;
        }

        // ****************************************

        _position = _clientRectContainer.left - _clientRectItemNew.left
            + _positionItemActive
            + _directionDelta
            + _positionDelta
        ;

        // ****************************************

        return [ _position, _positionTransit ];
    }

    /**
     *
     * @param _position
     * @private
     */
    function _goToPosition( _position ){

        if ( __type__.isNumber( _position ) ) {

            _NODE_.list.style.transform = 'translate3d(' + _position + 'px, 0px, 0px)';
        }
    }

    function _slideToIndex( _index, _bAnimate, _funcCallBack ){

        _bSliding = true;

        /** @private {boolean} */
        let _bUseCloneRightSide = _indexActive === 0 && _index === 4;

        function _callback(){

            __class__.remove( node, _CLASS_.sliding );

            if( __type__.isNumber( _positionNewTransit ) ) {

                ( _bUseCloneRightSide ) ?
                    __class__.remove(_NODE_.clones_right[_index], _CLASS_.active) :
                    __class__.remove(_NODE_.clones_left[_index], _CLASS_.active);

                __class__.add( _NODE_.items[ _index ], _CLASS_.active );

                _goToPosition( _positionNewAnimated );
            }

            _positionItemActive = _positionNewAnimated;
            _bSliding = false;
            __function__.execute( _funcCallBack, _index );
        }

        let [ _positionNewAnimated, _positionNewTransit ] = _getPositionX( _index );

        if( _bAnimate ){

            __class__.add( node, _CLASS_.sliding );

            __class__.remove( _NODE_.items[ _indexActive ], _CLASS_.active );

            if( __type__.isNumber( _positionNewTransit ) ) {

                ( _bUseCloneRightSide )
                    ? __class__.add(_NODE_.clones_right[_index], _CLASS_.active )
                    : __class__.add(_NODE_.clones_left[_index], _CLASS_.active );

                _goToPosition( _positionNewTransit );

            }else{

                __class__.add( _NODE_.items[_index], _CLASS_.active );

                _goToPosition( _positionNewAnimated );
            }

            setTimeout( _callback, _SETTINGS_.duration );
        }else{

            __class__.remove( _NODE_.items[ _indexActive ], _CLASS_.active );
            __class__.add( _NODE_.items[ _index ], _CLASS_.active );

            _goToPosition( _positionNewAnimated );
            _callback();
        }
    }

    // ****************************************

    /**
     *
     * @param {number} _index
     * @param {boolean} _bAnimate
     * @private
     */
    function _renderInformer( _index, _bAnimate = false ){

        if( _SETTINGS_.informer && __object__.notEmpty( _INFORMER_ ) && _NODE_.informer && _NODE_.informer_wrapper ){

            if( _NODE_.informer_wrapper.children.length === 0 || _index !== _indexActive ) {

                __node__.empty( _NODE_.informer_wrapper );

                if ( __object__.notEmpty( _INFORMER_[_index] ) ) {

                    let _nodeNewInformer = __node__.create([_CLASS_.informer_container, 'new'], null, _NODE_.informer_wrapper, 'div');

                    if( __string__.notEmpty( _INFORMER_[_index].head ) ) {

                        __node__.create(_CLASS_.informer_head, null, _nodeNewInformer, 'div', _INFORMER_[_index].head);
                    }

                    if( __string__.notEmpty( _INFORMER_[_index].title ) ) {

                        __node__.create(_CLASS_.informer_title, null, _nodeNewInformer, 'div', _INFORMER_[_index].title);
                    }

                    if( __string__.notEmpty( _INFORMER_[_index].description ) ) {

                        __node__.create(_CLASS_.informer_description, null, _nodeNewInformer, 'div', _INFORMER_[_index].description);
                    }
                }
            }
        }
    }

    // ****************************************

    function _setSettingsPosition( _position ){

        if( _SETTINGS_.position !== _POSITION[ _position ] ) {

            __class__.remove( node, _CLASS_.position_right );
            __class__.remove( node, _CLASS_.position_center );

            switch( _position ){

                case _STRING_RIGHT_:
                    _SETTINGS_.position = _POSITION_RIGHT_;
                    __class__.add( node, _CLASS_.position_right );
                    break;

                case _STRING_CENTER_:
                    _SETTINGS_.position = _POSITION_CENTER_;
                    __class__.add( node, _CLASS_.position_center );
                    break;

                case _STRING_LEFT_: default:
                    _SETTINGS_.position = _POSITION_LEFT_;
                    break;
            }
        }
    }

    // ****************************************

    function _setSettings( _key, _value ){

        switch ( _key ){
            case _STRING_POSITION_:
                _setSettingsPosition( _value );
                break;
        }
    }

    // ****************************************

    /**
     *
     * @param {string} _type
     * @param {Function} _function
     * @param {boolean} _one
     */
    function _addEventListener( _type, _function, _one = false ){

        if( __string__.notEmpty( _type ) && __type__.isFunction( _function ) ){

            if( [ _STRING_RESIZE_ ].indexOf( _type ) !== -1 ){

                ( _events[ _STRING_RESIZE_ ] = _events[ _STRING_RESIZE_ ] || [] ).push( { func: _function, one:  ( _one === true ) } );
            }
        }
    }

    /**
     *
     * @param {string} _type
     * @param {Function} _function
     * @private
     */
    function _removeEventListener( _type, _function ) {

        if( __type__.isFunction( _function ) && __object__.notEmpty( _events ) && __array__.notEmpty( _events[ _type ] ) ){

            _events[ _type ].map(function( _listener, _index ){

                if( _listener && __object__.notEmpty( _listener ) && __type__.isFunction( _listener.func ) && _listener.func === _function ){

                    _events[ _type ][ _index ] = null;
                }
            });
        }
    }

    /**
     *
     * @param {string} _type
     * @private
     */
    function _eventMap( _type ){

        if( __string__.notEmpty( _type ) && __object__.notEmpty( _events ) && __array__.notEmpty( _events[ _type ] ) ){

            _events[ _type ].map( function( _listener, _index ){

                if( _listener && __object__.notEmpty( _listener ) ){

                    __function__.execute( _listener.func );

                    if( _listener.one === true ){

                        _events[ _type ][ _index ] = null;
                    }
                }
            });
        }

        _eventGarbageCollector( _type );
    }

    /**
     *
     * @param {string} _type
     * @private
     */
    function _eventGarbageCollector( _type ){

        /**
         *
         * @param {string} _type
         * @private
         */
        function _byType( _type ){

            const _eventTypeNew = [];

            if( __string__.notEmpty( _type ) && __object__.notEmpty( _events ) && __array__.notEmpty( _events[ _type ] ) ){

                _events[ _type ].map( function( _listener, _index ){

                    if( !( _listener === null || ( __object__.notEmpty( _listener ) && !__type__.isFunction( _listener.func ) ) ) ){

                        _eventTypeNew.push( _listener );
                    }
                });

                _events[ _type ] = _eventTypeNew;
            }
        }

        if( __string__.notEmpty( _type ) ){

            _byType( _type );
        }
    }

    // ****************************************
    // Define Properties
    // ****************************************

    Object.defineProperties( __slider__, {

        refresh:            { value: function(){ _goToIndex( _indexActive, false ); } },
        setSettings:        { value: _setSettings },
        addEventListener:   { value: _addEventListener }
    });

    // ****************************************
    // MAIN
    // ****************************************

    if( __node__.isNode( node ) ){

        _initSettings();

        if( _getList() && __array__.notEmpty( _getListItems() ) ){

            _initDom();

            __node__.append( _NODE_.list, _getContainer(), null );
            _indexMaximumPosition = ( _NODE_.list.children.length - 1 );

            // ****************************************
            // If Loop create Clone
            // ****************************************

            if( _NODE_.items.length > 1 && _SETTINGS_.loop ){

                /** @private {number} */
                let _listItemsCount = _NODE_.items.length;

                _bCloneCreated = true;

                for( let i =0; i < _listItemsCount; i++ ){

                    _NODE_.clones_left[i] = _NODE_.items[i].cloneNode(true );
                    _NODE_.clones_right[i] = _NODE_.items[i].cloneNode(true );

                    if( __class__.has( _NODE_.items[i], _CLASS_.active ) ) {

                        __class__.remove(_NODE_.clones_left[i], _CLASS_.active);
                        __class__.remove(_NODE_.clones_right[i], _CLASS_.active);
                    }

                    if( i !== ( _listItemsCount - 1 ) ) {

                        __node__.append(_NODE_.clones_left[i], _NODE_.list, 'last');
                    }

                    if( i !== 0 ) {

                        __node__.append(_NODE_.clones_right[i], _NODE_.items[0], 'before');
                    }
                }
            }

            // ****************************************
            // Render Informer
            // ****************************************

            if( _SETTINGS_.informer ){ _getInformer(); }

            // ****************************************
            // Render Pagination
            // ****************************************

            if( _SETTINGS_.pagination ){

                if( !_SETTINGS_.pagination_independently && _NODE_.informer ){

                    __node__.append( _getPagination(), _NODE_.informer, 'first' );
                }else{

                    __node__.append( _getPagination(), node, 'first' );
                }
            }

            // ****************************************
            // Init event
            // ****************************************

            _NODE_.items.map( _initListItem );

            if( _SETTINGS_.arrow ){

                _NODE_.pagination_forward.addEventListener( 'click', _arrowOnClick, false );
                _NODE_.pagination_backward.addEventListener( 'click', _arrowOnClick, false );
            }

            window.addEventListener('resize',function(){

                _eventMap( _STRING_RESIZE_ );
            });

            // ****************************************

            _indexActive = _indexActive === -1 ? 0 : _indexActive;

            _goToIndex( _indexActive, false );
        }
    }

    // ****************************************
}

/** @type {{constructor: Slider}} */
Slider.prototype  = {

    constructor: Slider
};

export default Slider;